import React, { useState, useEffect } from "react";

const LeftColumn = () => (
  <div style={{ maxWidth: "500px", textAlign: "left" }}>
    <h1
      style={{
        fontSize: "3rem",
        fontWeight: "bold",
        margin: "10px 0",
      }}
    >
      Reply to Instagram Comments with a DM, Instantly!
    </h1>
    <p style={{ fontSize: "1.2rem", color: "#007bff", margin: "10px 0" }}>
      #1 Instagram DM Automation Platform for Creators
    </p>
    <ul
      style={{
        listStyle: "none",
        padding: 0,
        margin: "20px 0",
        lineHeight: "1.6",
        color: "#333",
      }}
    >
      <li>✅ Instagram-approved platform</li>
      <li>🎉 Used by 16,000+ creators</li>
      <li>💯 Get started in seconds</li>
    </ul>
    <button
      onClick={() => (window.location.href = "/signup")}
      style={{
        marginRight: "10px", // Optional spacing between buttons
        backgroundColor: "#007bff",
        color: "#fff",
        padding: "8px 15px",
        borderRadius: "5px",
        border: "none",
        cursor: "pointer",
        fontSize: "1rem",
      }}
    >
      Create Account - It's Free!
    </button>
    <p style={{ fontSize: "0.9rem", color: "#888", marginTop: "10px" }}>
      No credit card required
    </p>
  </div>
);

const RightColumn = () => {
  const images = [
    "heroImage1.png", // Replace with your actual image paths
    "heroImage2.png",
    "heroImage3.png",
  ];

  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <div
      style={{
        maxWidth: "500px",
        textAlign: "center",
      }}
    >
      <img
        src={images[currentImage]}
        alt="Instagram Example"
        style={{
          width: "100%",
          height: "auto",
          border: "1px solid #ddd",
          borderRadius: "8px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        }}
      />
    </div>
  );
};

const MainContent = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      padding: "40px 80px",
    }}
  >
    <LeftColumn />
    <RightColumn />
  </div>
);

export default MainContent;
