import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Chip,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { styled } from "@mui/system";
import APIHelper from "./utils/APIHelper";

// ✅ Reusing the Preview design from AutoCommentConfig
const PreviewBox = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "400px",
  backgroundImage: "url(/preview.png)",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const PreviewText = styled(Typography)(({ theme }) => ({
  position: "absolute",
  bottom: "60px",
  textAlign: "center",
  maxWidth: "40%",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  padding: theme.spacing(1),
  borderRadius: "8px",
  fontSize: "10px",
  wordWrap: "break-word",
  marginLeft: "25px",
}));

const MessageTemplateConfig = ({ token, postId, onClose }) => {
  const [keywords, setKeywords] = useState([]);
  const [messageTemplate, setMessageTemplate] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const [commentToCheck, setCommentToCheck] = useState("");

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const config = postId
          ? await APIHelper.fetchMediaAutoCommentConfig(token, postId)
          : await APIHelper.fetchAutoCommentConfig(token);

        if (config) {
          setKeywords(config.commentKeywords || []);
          setMessageTemplate(config.templateMessage || "");
          setIsEnabled(config.isEnabled || false);
        }
      } catch (error) {
        toast.error("Failed to load configuration");
      }
    };

    fetchTemplate();
  }, [token, postId]);

  const handleAddKeyword = () => {
    if (commentToCheck.trim() && !keywords.includes(commentToCheck.trim())) {
      setKeywords([...keywords, commentToCheck.trim()]);
      setCommentToCheck("");
    }
  };

  const handleDeleteKeyword = (keyword) => {
    setKeywords(keywords.filter((k) => k !== keyword));
  };

  const handleSave = async () => {
    try {
      const config = {
        commentKeywords: keywords,
        templateMessage: messageTemplate,
        isEnabled,
        templateType: "MessageTemplate",
      };
      if (postId) {
        await APIHelper.updateMediaAutoCommentConfig(token, postId, config);
      } else {
        await APIHelper.updateAutoCommentConfig(token, config);
      }
      toast.success("Message template saved successfully!");
      onClose();
    } catch (error) {
      toast.error("Failed to save message template.");
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      {/* Configuration Section */}
      <Box sx={{ flex: 2 }}>
        <TextField
          fullWidth
          label="Template Message for DM"
          value={messageTemplate}
          onChange={(e) => setMessageTemplate(e.target.value)}
          placeholder="e.g., Thanks for your interest! Visit our link: ..."
          variant="outlined"
          margin="normal"
        />

        <TextField
          fullWidth
          label="Add Comment Keywords (Press Enter to add)"
          value={commentToCheck}
          onChange={(e) => setCommentToCheck(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleAddKeyword()}
          variant="outlined"
          margin="normal"
        />

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
          {keywords.map((keyword, index) => (
            <Chip
              key={index}
              label={keyword}
              onDelete={() => handleDeleteKeyword(keyword)}
              color="primary"
            />
          ))}
        </Box>

        <FormControlLabel
          control={
            <Switch
              checked={isEnabled}
              onChange={(e) => setIsEnabled(e.target.checked)}
            />
          }
          label="Enable Auto DM"
        />
        <Box sx={{ width: "100%" }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleSave}
          >
            Save Message Template
          </Button>
        </Box>
      </Box>

      {/* 🔥 Reused Preview Section */}
      <Box sx={{ flex: 1 }}>
        <PreviewBox>
          <PreviewText>
            {messageTemplate ||
              "Your template message preview will appear here."}
          </PreviewText>
        </PreviewBox>
      </Box>
    </Box>
  );
};

export default MessageTemplateConfig;
