import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  Divider,
} from "@mui/material";

const PricingPage = () => (
  <div id="pricing">
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={4}
      flexWrap="wrap"
      padding={4}
    >
      <PricingCard
        title="Free"
        price="$0"
        features={[
          "Access Last 10 Posts",
          "Link Unlimited Posts",
          "Posts & Reels Auto-Reply",
          "Inbox Conversation Staters",
        ]}
        buttonText="Get Started for Free"
        buttonStyle={{ backgroundColor: "#007bff", color: "#fff" }}
      />
      <PricingCard
        title="Pro"
        price="$9"
        features={[
          "Remove Watermark Branding",
          "Comment Auto-Reply",
          "Slow Down Mode",
          "Universal Auto-Reply",
        ]}
        buttonText="Upgrade to Pro"
        buttonStyle={{ backgroundColor: "#ffc107", color: "#333" }}
      />
      <PricingCard
        title="Platinum+"
        price="$49"
        features={[
          "Early Access New Features",
          "Live Chat with our Team",
          "DM Overflow Queue",
          "Priority Support",
        ]}
        buttonText="Upgrade to Platinum+"
        buttonStyle={{ backgroundColor: "#333", color: "#fff" }}
      />
    </Box>
  </div>
);

const PricingCard = ({ title, price, features, buttonText, buttonStyle }) => (
  <Card
    elevation={3}
    sx={{
      maxWidth: 350,
      borderRadius: 3,
      border: "1px solid #ddd",
      transition: "transform 0.3s",
      "&:hover": { transform: "scale(1.05)" },
    }}
  >
    <CardContent>
      <Typography variant="h5" fontWeight="bold" align="center" gutterBottom>
        {title}
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography
        variant="h4"
        fontWeight="bold"
        align="center"
        color="primary.main"
      >
        {price}
      </Typography>
      <List sx={{ mt: 2, mb: 4 }}>
        {features.map((feature, index) => (
          <ListItem key={index} disableGutters sx={{ py: 0.5 }}>
            ✅ {feature}
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        sx={{
          ...buttonStyle,
          width: "100%",
          padding: "10px 20px",
          borderRadius: 2,
        }}
        onClick={() => (window.location.href = "/signup")}
      >
        {buttonText}
      </Button>
    </CardContent>
  </Card>
);

export default PricingPage;
