import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Typography,
  ButtonGroup,
  FormControlLabel,
  Switch,
  Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import APIHelper from "./utils/APIHelper";
import ButtonTemplatePreview from "./ButtonTemplatePreview";
import { Close as CloseIcon } from "@mui/icons-material";

const ButtonTemplateConfig = ({ token, postId, onClose }) => {
  const [keywords, setKeywords] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const [commentToCheck, setCommentToCheck] = useState("");
  const [templates, setTemplates] = useState([
    {
      headline: "",
      description: "",
      buttons: [{ name: "", url: "" }],
      imageUrl: "",
    },
  ]);
  const [cardTemplates, setCardTemplates] = useState(templates);
  const [activeTemplateIndex, setActiveTemplateIndex] = useState(0);

  useEffect(() => {
    if (Array.isArray(cardTemplates)) {
      setTemplates(
        cardTemplates.length
          ? cardTemplates
          : [
              {
                headline: "",
                description: "",
                buttons: [{ name: "", url: "" }],
                imageUrl: "",
              },
            ]
      );
    }
  }, [cardTemplates]);

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const config = postId
          ? await APIHelper.fetchMediaAutoCommentConfig(token, postId)
          : await APIHelper.fetchAutoCommentConfig(token);

        if (config) {
          setKeywords(config.commentKeywords || []);
          setCardTemplates(config.cardTemplates || "");
          setIsEnabled(config.isEnabled || false);
        }
      } catch (error) {
        toast.error("Failed to load configuration");
      }
    };

    fetchTemplate();
  }, [token, postId]);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    try {
      const response = await APIHelper.uploadImageToCloudinary(file);
      const uploadedImageUrl = response?.secure_url;

      if (uploadedImageUrl) {
        setTemplates((prev) => {
          const updatedTemplates = [...prev];
          updatedTemplates[activeTemplateIndex].imageUrl = uploadedImageUrl;
          return updatedTemplates;
        });
      }
    } catch (error) {
      toast.error("Failed to upload image. Please try again.");
    }

    e.target.value = ""; // 🔥 Reset input to allow re-uploading the same image
  };

  const handleRemoveImage = () => {
    setTemplates((prev) => {
      const updatedTemplates = [...prev];
      updatedTemplates[activeTemplateIndex].imageUrl = "";
      return updatedTemplates;
    });
  };
  // Example of updating the template inside ButtonTemplateConfig
  const handleTemplateChange = (field, value) => {
    setTemplates((prev) => {
      const updatedTemplates = prev.map((template, index) =>
        index === activeTemplateIndex
          ? { ...template, [field]: value }
          : template
      );

      // Ensure cardTemplates also gets updated with the latest changes
      setCardTemplates(updatedTemplates);

      return updatedTemplates;
    });
  };

  const handleAddButton = () => {
    setTemplates((prev) => {
      const updatedTemplates = [...prev];
      if (updatedTemplates[activeTemplateIndex].buttons.length < 3) {
        updatedTemplates[activeTemplateIndex].buttons.push({
          name: "",
          url: "",
        });
      } else {
        toast.error("You can only add up to 3 buttons per template.");
      }
      return updatedTemplates;
    });
  };

  const handleDeleteButton = (buttonIndex) => {
    setTemplates((prev) => {
      const updatedTemplates = [...prev];
      const buttons = updatedTemplates[activeTemplateIndex].buttons;
      if (buttons.length > 1) {
        buttons.splice(buttonIndex, 1);
      } else {
        toast.error("At least one button is required.");
      }
      return updatedTemplates;
    });
  };

  const handleButtonChange = (buttonIndex, field, value) => {
    setTemplates((prev) => {
      const updatedTemplates = [...prev];
      updatedTemplates[activeTemplateIndex].buttons[buttonIndex] = {
        ...updatedTemplates[activeTemplateIndex].buttons[buttonIndex],
        [field]: value,
      };
      return updatedTemplates;
    });
  };

  const handleAddTemplate = () => {
    setTemplates((prev) => [
      ...prev,
      {
        headline: "",
        description: "",
        buttons: [{ name: "", url: "" }],
        imageUrl: "",
      },
    ]);
    setActiveTemplateIndex((prev) => prev + 1);
  };

  const handleRemoveTemplate = () => {
    if (templates.length > 1) {
      setTemplates((prev) => prev.slice(0, -1));
      setActiveTemplateIndex((prev) => prev - 1);
    } else {
      toast.error("At least one template is required.");
    }
  };

  const handleAddKeyword = () => {
    if (commentToCheck.trim() && !keywords.includes(commentToCheck.trim())) {
      setKeywords([...keywords, commentToCheck.trim()]);
      setCommentToCheck("");
    }
  };

  const handleDeleteKeyword = (keywordToDelete) => {
    setKeywords(keywords.filter((keyword) => keyword !== keywordToDelete));
  };

  const handleSave = async () => {
    try {
      const config = {
        commentKeywords: keywords,
        cardTemplates,
        isEnabled,
        templateType: "ButtonTemplate",
      };
      if (postId) {
        await APIHelper.updateMediaAutoCommentConfig(token, postId, config);
      } else {
        await APIHelper.updateAutoCommentConfig(token, config);
      }
      console.log(config);
      toast.success("Configuration saved successfully");
      onClose();
    } catch (error) {
      toast.error("Failed to save configuration");
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 2, height: "500px" }}>
      <Box
        sx={{
          flex: 2,
          overflowY: "auto",
          maxHeight: "100%",
          p: 2,
        }}
      >
        <Typography variant="subtitle1" mb={1}>
          Carousel Slider
        </Typography>
        <ButtonGroup variant="outlined">
          {templates.map((_, index) => (
            <Button key={index} onClick={() => setActiveTemplateIndex(index)}>
              {index + 1}
            </Button>
          ))}
          <Button onClick={handleAddTemplate}>+</Button>
          {templates.length > 1 && (
            <Button onClick={handleRemoveTemplate}>x</Button>
          )}
        </ButtonGroup>

        <Box mt={2}>
          <Box mt={2}>
            <Typography variant="subtitle1">Image</Typography>
            <Button variant="contained" component="label">
              Upload
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleImageUpload}
              />
            </Button>

            {templates[activeTemplateIndex].imageUrl && (
              <Box
                sx={{
                  position: "relative",
                  width: "40px",
                  height: "40px",
                  borderRadius: "8px",
                  overflow: "visible",
                  border: "1px solid gray",
                  mt: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={templates[activeTemplateIndex].imageUrl}
                  alt="Uploaded"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "-5px",
                    right: "-5px",
                    backgroundColor: "white",
                    color: "black",
                    width: "16px",
                    height: "16px",
                    p: 0,
                    minWidth: "unset",
                    boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
                    "&:hover": { backgroundColor: "#f0f0f0" },
                  }}
                  onClick={handleRemoveImage}
                >
                  <CloseIcon sx={{ fontSize: "12px" }} />
                </IconButton>
              </Box>
            )}

            {/* Text fields */}
            <TextField
              fullWidth
              label="Headline"
              value={templates[activeTemplateIndex].headline}
              onChange={(e) => handleTemplateChange("headline", e.target.value)}
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="Description"
              value={templates[activeTemplateIndex].description}
              onChange={(e) =>
                handleTemplateChange("description", e.target.value)
              }
              variant="outlined"
              margin="normal"
            />
          </Box>

          {templates[activeTemplateIndex].buttons.map((button, buttonIndex) => (
            <Box
              key={buttonIndex}
              display="flex"
              alignItems="center"
              gap={1}
              mt={1}
              mb={1}
            >
              <TextField
                label="Button URL"
                value={button.url}
                onChange={(e) =>
                  handleButtonChange(buttonIndex, "url", e.target.value)
                }
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Button Name"
                value={button.name}
                onChange={(e) =>
                  handleButtonChange(buttonIndex, "name", e.target.value)
                }
                variant="outlined"
                fullWidth
              />
              <IconButton
                color="error"
                onClick={() => handleDeleteButton(buttonIndex)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}

          <Button
            variant="contained"
            onClick={handleAddButton}
            disabled={templates[activeTemplateIndex].buttons.length >= 3}
          >
            Add Additional Button
          </Button>

          <TextField
            fullWidth
            label="Add Comment Keywords (Press Enter to add)"
            value={commentToCheck}
            onChange={(e) => setCommentToCheck(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAddKeyword();
              }
            }}
            placeholder="Type a keyword and press Enter"
            variant="outlined"
            margin="normal"
          />
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
            {keywords.map((keyword, index) => (
              <Chip
                key={index}
                label={keyword}
                onDelete={() => handleDeleteKeyword(keyword)}
                color="primary"
              />
            ))}
          </Box>

          <FormControlLabel
            control={
              <Switch
                checked={isEnabled}
                onChange={(e) => setIsEnabled(e.target.checked)}
              />
            }
            label="Enable Auto DM"
          />
          <Box sx={{ width: "100%" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleSave}
            >
              Save Template
            </Button>
          </Box>
        </Box>
      </Box>

      {/* 🔥 Reused Preview Section */}
      <Box sx={{ flex: 1, ml: 10 }}>
        <ButtonTemplatePreview
          key={JSON.stringify(templates)}
          cardTemplates={templates}
          activeTemplateIndex={activeTemplateIndex}
        />
      </Box>
    </Box>
  );
};

export default ButtonTemplateConfig;
