import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  CircularProgress,
} from "@mui/material";
import APIHelper from "./utils/APIHelper";
import TopBar from "../LandingPage/TopBar";
import CommentsViewer from "./CommentsViewer";
import PagePosts from "./PagePosts";
import MessageCounter from "./MessageCounter";
import MediaTable from "./MediaTable";

const AppPage = ({ token }) => {
  const [profileData, setProfileData] = useState(null);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token) {
      fetchProfileAndPages(token);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchPreferredPage(token);
    }
  }, [token]);

  const fetchProfileAndPages = async (token) => {
    setLoading(true);
    try {
      const profileResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/profile`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (profileResponse.status === 200) {
        setProfileData(profileResponse.data);
      }

      const pagesResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/pages`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (pagesResponse.status === 200) {
        setPages(pagesResponse.data.data);
      }
    } catch (error) {
      console.error("Error fetching profile data or pages:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPreferredPage = async (token) => {
    try {
      const preferredPage = await APIHelper.fetchPreferredPage(token);
      setSelectedPage(preferredPage);
    } catch (error) {
      console.error("Error fetching preferred page:", error);
    }
  };

  const handlePageSelect = async (pageId) => {
    setSelectedPage(pageId);
    try {
      await APIHelper.updatePreferredPage(token, pageId);
    } catch (error) {
      console.error("Error updating preferred page:", error);
    }
  };

  return (
    <>
      <TopBar
        onClearConfiguration={async () => {
          try {
            await APIHelper.clearPreferredPage(token);
            await APIHelper.clearAutoCommentConfig(token);
            setSelectedPage(null);
          } catch (error) {
            console.error("Error clearing configuration:", error);
          }
        }}
        onDisconnectFB={async () => {
          try {
            await APIHelper.disconnectFacebook(token);
            window.location.href = "/app";
          } catch (error) {
            console.error("Error clearing configuration:", error);
          }
        }}
        isConfigured={!!selectedPage}
        token={token}
      />

      <Container>
        {profileData ? (
          <Box mt={4}>
            {/* Align Welcome Message and MessageCounter */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={3}
            >
              {/* Welcome Message */}
              <Typography variant="h5">
                Hi {profileData.name}, Welcome back!
              </Typography>

              {/* MessageCounter */}
              {selectedPage && (
                <Box display="flex" justifyContent="flex-end" width="30%">
                  <MessageCounter token={token} pageId={selectedPage} />
                </Box>
              )}
            </Box>

            {!selectedPage ? (
              <>
                <Typography variant="h6" gutterBottom>
                  Select a page to complete your setup:
                </Typography>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>Select a Page</InputLabel>
                  <Select
                    value={selectedPage || ""}
                    onChange={(e) => handlePageSelect(e.target.value)}
                    label="Select a Page"
                  >
                    <MenuItem value="">
                      <em>-- Select --</em>
                    </MenuItem>
                    {pages.map((page) => (
                      <MenuItem key={page.id} value={page.id}>
                        {page.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                <PagePosts token={token} pageId={selectedPage} />
                <MediaTable token={token} pageId={selectedPage} />
                <CommentsViewer token={token} pageId={selectedPage} />
              </>
            )}
          </Box>
        ) : loading ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Typography variant="h6" color="error" align="center">
            Unable to load profile data. Please try again.
          </Typography>
        )}
      </Container>
    </>
  );
};

export default AppPage;
