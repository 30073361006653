import React, { useState, useEffect } from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import axios from "axios";

const MessageCounter = ({ token, pageId }) => {
  const [count, setCount] = useState(0);
  const maxLimit = 50000;

  useEffect(() => {
    if (token && pageId) {
      fetchMessageCount(token, pageId);
    }
  }, [token, pageId]);

  const fetchMessageCount = async (token, pageId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/report/successful-messages-count?pageId=${pageId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        setCount(response.data.count);
      }
    } catch (error) {
      console.error("Error fetching message count:", error);
    }
  };

  return (
    <Box width="100%" align="right">
      {/* Line 1: Title */}
      <Typography variant="subtitle1" gutterBottom align="right">
        MONTHLY DM SEND LIMIT
      </Typography>

      {/* Line 2: Progress Bar */}
      <LinearProgress
        variant="determinate"
        value={(count / maxLimit) * 100}
        sx={{ height: 8, borderRadius: 4, mb: 1 }}
      />

      {/* Line 3: Count */}
      <Typography variant="body2" align="right">
        {count.toLocaleString()}/{maxLimit.toLocaleString()}
      </Typography>
    </Box>
  );
};

export default MessageCounter;
