import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Link, Button } from "@mui/material";
import TopBar from "../LandingPage/TopBar";
import { jwtDecode } from "jwt-decode";

const ConnectorPage = ({ token, onAccessTokenUpdated }) => {
  const navigate = useNavigate();

  // Parse URL parameters on page load
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get("status");
    const error = urlParams.get("error");

    if (status === "success") {
      // Simulate adding the accessToken to the token
      const updatedToken = {
        ...jwtDecode(token),
        accessToken: "newAccessToken",
      };
      const encodedToken = btoa(JSON.stringify(updatedToken)); // Re-encode the updated token
      localStorage.setItem("token", encodedToken);

      onAccessTokenUpdated(); // Notify parent to update state
      alert("Facebook connected successfully!");
      navigate("/dashboard"); // Redirect to the dashboard
    }

    if (error) {
      // Handle error scenario
      alert(`Error: ${error}`);
    }
  }, [token, navigate, onAccessTokenUpdated]);

  const handleFacebookLogin = async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      if (!backendUrl) {
        throw new Error("Backend URL is not defined");
      }

      // Redirect to backend for OAuth flow with token
      window.location.href = `${backendUrl}/auth/facebook?token=${encodeURIComponent(
        token
      )}`;
    } catch (error) {
      console.error("Error during authentication:", error);
    }
  };

  return (
    <>
      <TopBar />

      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          height: "100vh",
        }}
      >
        <Typography
          variant="h4"
          style={{ marginBottom: "10px", fontWeight: "bold" }}
        >
          Attach Instagram Account
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "20px" }}>
          Login with Facebook to access your Instagram account
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFacebookLogin}
          style={{
            backgroundColor: "#007bff",
            color: "#fff",
            padding: "10px 20px",
            borderRadius: "5px",
            fontSize: "1rem",
          }}
        >
          Connect with Facebook
        </Button>
        <Link
          href="#"
          style={{
            marginTop: "15px",
            fontSize: "0.9rem",
            color: "#6c757d",
            textDecoration: "none",
          }}
          onClick={() => {
            alert(
              "Meta requires that an Instagram account must be connected to a Facebook page in order to use a 3rd party platform like InsBuy"
            );
          }}
        >
          Why do I need to login with Facebook?
        </Link>
      </Container>
    </>
  );
};

export default ConnectorPage;
