import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + "...";
};

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleString();
};

const CommentsViewer = ({ token, pageId }) => {
  const [postDataWithComments, setPostDataWithComments] = useState([]);

  const handleGetCommentsClick = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/instagram/posts/${pageId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPostDataWithComments(response.data);
    } catch (error) {
      console.error("Error getting comments:", error);
    }
  };

  const handleDM = async (pageId, commentId) => {
    try {
      const message = prompt("Enter your DM:");
      if (!message) return;

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/instagram/message`,
        JSON.stringify({
          pageId: pageId,
          message: message,
          commentId: commentId,
        }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Direct message sent:", response.data);
      toast.success("Direct message sent successfully");
    } catch (error) {
      console.error("Error sending direct message:", error);
      toast.error("Error sending direct message");
    }
  };

  const handleReply = async (commentId) => {
    try {
      const message = prompt("Enter your reply:");
      if (!message) return;

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/instagram/comment/${commentId}/replies`,
        {
          message: message,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Reply sent:", response.data);
      toast.success("Reply sent successfully");
    } catch (error) {
      console.error("Error replying to comment:", error);
      toast.error("Error replying to comment");
    }
  };

  return (
    <Box mt={4}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGetCommentsClick}
      >
        Get Comments
      </Button>
      <Box mt={2}>
        {postDataWithComments.map((postData, index) => (
          <Card key={index} sx={{ mb: 4 }}>
            <CardContent>
              <Typography variant="h6">Media {index + 1}</Typography>
              <Box display="flex" gap={2} mt={2}>
                {postData.media_type === "IMAGE" && (
                  <CardMedia
                    component="img"
                    image={postData.media_url}
                    alt="Instagram post"
                    sx={{ width: 150, height: 150 }}
                  />
                )}
                {postData.media_type === "VIDEO" && (
                  <CardMedia
                    component="img"
                    image={postData.thumbnail_url}
                    alt="Video thumbnail"
                    sx={{ width: 150, height: 150 }}
                  />
                )}
                <Box>
                  <Typography>
                    <strong>Media ID:</strong> {postData.id}
                  </Typography>
                  <Typography>
                    <strong>Timestamp:</strong>{" "}
                    {formatTimestamp(postData.timestamp)}
                  </Typography>
                  <Typography>
                    <strong>Media Caption:</strong>{" "}
                    {truncateText(postData.caption, 50)}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="h6" mt={2}>
                Comments:
              </Typography>
              <List>
                {postData.comments &&
                  postData.comments.data.map((comment, commentIndex) => (
                    <React.Fragment key={commentIndex}>
                      <ListItem>
                        <ListItemText
                          primary={`${comment.username}: ${comment.text}`}
                          secondary={formatTimestamp(comment.timestamp)}
                        />
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleReply(comment.id)}
                          sx={{ mr: 1 }}
                        >
                          Reply
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => handleDM(pageId, comment.id)}
                        >
                          DM
                        </Button>
                      </ListItem>
                      {commentIndex < postData.comments.data.length - 1 && (
                        <Divider />
                      )}
                    </React.Fragment>
                  ))}
              </List>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default CommentsViewer;
