import React, { useState, useEffect } from "react";
import AuthButton from "../Application/AuthButton";
import AutoCommentConfig from "../Application/AutoCommentConfig";

const TopBar = ({
  onAuthSuccess,
  onClearConfiguration,
  onDisconnectFB,
  isConfigured,
  token,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    // Check if the token exists in localStorage or cookies
    const token = localStorage.getItem("token"); // or use cookies
    setIsLoggedIn(!!token);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 20px",
        backgroundColor: "#fff",
        borderBottom: "1px solid #ddd",
      }}
    >
      <div style={{ fontSize: "1.2rem", fontWeight: "bold", color: "#333" }}>
        InsBuy
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <a
          href="#pricing"
          style={{
            marginRight: "15px",
            textDecoration: "none",
            color: "#555",
            fontSize: "1rem",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.preventDefault();
            document.getElementById("pricing").scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          Pricing
        </a>
        {isConfigured && (
          <>
            <a
              href="#AutoReplyConfig"
              style={{
                marginRight: "15px",
                textDecoration: "none",
                color: "#555",
                fontSize: "1rem",
                cursor: "pointer",
              }}
              onClick={handleOpenModal}
            >
              Auto Reply Config
            </a>
            <a
              href="#configuration"
              style={{
                marginRight: "15px",
                textDecoration: "none",
                color: "#555",
                fontSize: "1rem",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                if (onClearConfiguration) {
                  onClearConfiguration();
                }
              }}
            >
              Page Config
            </a>
            <a
              href="#configuration"
              style={{
                marginRight: "15px",
                textDecoration: "none",
                color: "#555",
                fontSize: "1rem",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                if (onDisconnectFB) {
                  onDisconnectFB();
                }
              }}
            >
              Disconnect FB
            </a>
          </>
        )}
        {!isLoggedIn ? (
          <>
            <a
              href="/login"
              style={{
                marginRight: "15px",
                textDecoration: "none",
                color: "#555",
                fontSize: "1rem",
                cursor: "pointer",
              }}
            >
              Login
            </a>
            <button
              onClick={() => (window.location.href = "/signup")}
              style={{
                marginRight: "10px", // Optional spacing between buttons
                backgroundColor: "#007bff",
                color: "#fff",
                padding: "8px 15px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                fontSize: "1rem",
              }}
            >
              Create Free Account
            </button>
          </>
        ) : (
          <button
            onClick={() => {
              localStorage.removeItem("token");
              setIsLoggedIn(false);
              window.location.href = "/";
            }}
            style={{
              marginRight: "10px", // Optional spacing between buttons
              backgroundColor: "#007bff",
              color: "#fff",
              padding: "8px 15px",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              fontSize: "1rem",
            }}
          >
            Logout
          </button>
        )}
        {/* <AuthButton
          style={{
            marginRight: "10px", // Optional spacing between buttons
            backgroundColor: "#007bff",
            color: "#fff",
            padding: "8px 15px",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
            fontSize: "1rem",
          }}
          customButtonName="Log In with Facebook"
          onAuthSuccess={onAuthSuccess}
        /> */}
      </div>
      {isModalOpen && (
        <AutoCommentConfig
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          token={token}
        />
      )}
    </div>
  );
};

export default TopBar;
