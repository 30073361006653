import axios from "axios";

const APIHelper = {
  fetchPreferredPage: async (token) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/user/preferred-page`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data.preferredPage;
  },

  updatePreferredPage: async (token, preferredPage) => {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/user/preferred-page`,
      { preferredPage },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  },

  clearPreferredPage: async (token) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/user/preferred-page`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  },

  fetchAutoCommentConfig: async (token) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/user/auto-comment-config`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data.autoCommentConfig;
  },

  updateAutoCommentConfig: async (token, autoCommentConfig) => {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/user/auto-comment-config`,
      autoCommentConfig,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  },

  clearAutoCommentConfig: async (token) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/user/auto-comment-config`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  },

  fetchMediaAutoCommentConfig: async (token, mediaId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/media/auto-comment-config?mediaId=${mediaId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data.autoCommentConfig;
  },

  updateMediaAutoCommentConfig: async (token, mediaId, autoCommentConfig) => {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/media/auto-comment-config?mediaId=${mediaId}`,
      autoCommentConfig,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  },

  clearMediaAutoCommentConfig: async (token, mediaId) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/media/auto-comment-config?mediaId=${mediaId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  },

  disconnectFacebook: async (token) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/user/disconnect-facebook`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  },

  uploadImageToCloudinary: async (file) => {
    const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload`;
    const UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

    if (!file) {
      throw new Error("No file provided for upload");
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", UPLOAD_PRESET);

    try {
      const response = await fetch(CLOUDINARY_URL, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload image to Cloudinary");
      }

      const data = await response.json();
      return data; // Returns the full response from Cloudinary
    } catch (error) {
      console.error("Error uploading image to Cloudinary:", error);
      throw error;
    }
  },
};

export default APIHelper;
