import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import axios from "axios";
import AutoCommentConfig from "./AutoCommentConfig";

const MediaTable = ({ pageId, token }) => {
  const [mediaData, setMediaData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (postId) => {
    setSelectedPostId(postId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedPostId(null);
  };

  const columns = [
    // { field: "id", headerName: "Media ID", width: 130 },
    {
      field: "media_url",
      headerName: "Post",
      width: 100,
      renderCell: (params) => {
        const { media_type, media_url, thumbnail_url } = params.row;

        let imageSrc;

        if (media_type === "VIDEO") {
          imageSrc = thumbnail_url;
        } else if (media_type === "IMAGE") {
          imageSrc = media_url;
        } else {
          imageSrc = "/placeholder-image.png";
        }

        return (
          <img
            src={imageSrc}
            alt="Media Preview"
            style={{ maxWidth: "100px", maxHeight: "50px", objectFit: "cover" }}
            onError={(e) => {
              console.error("Image failed to load:", imageSrc);
            }}
          />
        );
      },
    },
    { field: "caption", headerName: "Description", width: 400 },
    {
      field: "status",
      headerName: "STATUS",
      width: 150,
      renderCell: (params) => {
        const isEnabled = params.row.autoCommentConfig?.isEnabled;

        return (
          <span
            onClick={() => handleOpenModal(params.row.id)}
            style={{
              padding: "4px 8px",
              borderRadius: "8px",
              color: "white",
              backgroundColor: isEnabled ? "green" : "red",
              fontSize: "12px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            {isEnabled ? "Configured" : "Not Configured"}
          </span>
        );
      },
    },
    {
      field: "totalMessagesSent",
      headerName: "Auto Messages Sent",
      width: 150,
    },
    // { field: "sent", headerName: "Sent", width: 100 },
    // { field: "open", headerName: "Open", width: 100 },
    // { field: "clicks", headerName: "Clicks", width: 100 },
    // { field: "ctr", headerName: "CTR", width: 100 },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/media/message-count?pageId=${pageId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setMediaData(response.data);
      } catch (error) {
        console.error("Error fetching media data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (pageId && token) {
      fetchData();
    }
  }, [pageId, token]);

  return (
    <div style={{ marginTop: "20px" }}>
      <h2>Recent Posts</h2>
      {loading && <p>Loading data...</p>}
      {error && <p>Error: {error}</p>}
      {mediaData.length > 0 && (
        <DataGrid
          rows={mediaData}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          getRowId={(row) => row.id}
          components={{
            Toolbar: () => (
              <Button variant="contained" color="primary">
                {/* Add functionality for your desired action */}
                View Details
              </Button>
            ),
          }}
        />
      )}
      {isModalOpen && (
        <AutoCommentConfig
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          token={token}
          postId={selectedPostId}
        />
      )}
    </div>
  );
};

export default MediaTable;
