import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Modal,
  IconButton,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MessageTemplateConfig from "./MessageTemplateConfig";
import ButtonTemplateConfig from "./ButtonTemplateConfig";
import { toast } from "react-toastify";
import APIHelper from "./utils/APIHelper";

const AutoCommentConfig = ({ token, isOpen, onClose, postId = undefined }) => {
  const [templateType, setTemplateType] = useState("MessageTemplate");

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const config = postId
          ? await APIHelper.fetchMediaAutoCommentConfig(token, postId)
          : await APIHelper.fetchAutoCommentConfig(token);

        if (config) {
          // Set the template type based on the fetched config
          // You might need to adjust this based on how your API returns the template type
          if (config.templateType) {
            setTemplateType(config.templateType);
          } else {
            // Default to MessageTemplate if no template type is specified
            setTemplateType("MessageTemplate");
          }
        }
      } catch (error) {
        toast.error("Failed to load configuration");
      }
    };

    if (isOpen) {
      fetchTemplate();
    }
  }, [token, postId, isOpen]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">
            Configure Auto DM for Comments Bot
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <FormControl component="fieldset" sx={{ mb: 2 }}>
          <RadioGroup
            row
            value={templateType}
            onChange={(e) => setTemplateType(e.target.value)}
          >
            <FormControlLabel
              value="MessageTemplate"
              control={<Radio />}
              label="Message Template"
            />
            <FormControlLabel
              value="ButtonTemplate"
              control={<Radio />}
              label="Button Template"
            />
          </RadioGroup>
        </FormControl>

        {templateType === "MessageTemplate" ? (
          <MessageTemplateConfig
            token={token}
            postId={postId}
            onClose={onClose}
          />
        ) : (
          <ButtonTemplateConfig
            token={token}
            postId={postId}
            onClose={onClose}
          />
        )}
      </Box>
    </Modal>
  );
};

export default AutoCommentConfig;
