import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";

const ButtonTemplatePreview = ({ cardTemplates, activeTemplateIndex }) => {
  const [parsedMessage, setParsedMessage] = useState({});

  useEffect(() => {
    if (Array.isArray(cardTemplates) && cardTemplates.length > 0) {
      setParsedMessage(cardTemplates[activeTemplateIndex]);
    } else {
      setParsedMessage({});
    }
  }, [cardTemplates, activeTemplateIndex]);

  if (!parsedMessage) return null;

  const {
    headline = "",
    description = "",
    buttons = [],
    imageUrl = "",
  } = parsedMessage;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "250px",
        height: "420px",
        borderRadius: "16px",
        backgroundColor: "white",
        boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
        overflow: "hidden",
      }}
    >
      {/* Image Section */}
      <Box
        sx={{
          width: "100%",
          height: "50%",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#e0e0e0",
          border: imageUrl ? "none" : "2px dashed black",
        }}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="Preview"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        ) : (
          <Typography color="gray">No Image</Typography>
        )}
      </Box>

      {/* Card Content */}
      <Box
        sx={{
          width: "100%",
          height: "50%",
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          p: 2,
          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px",
          gap: 1.5,
        }}
      >
        {/* Headline */}
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          sx={{
            maxWidth: "90%",
            textAlign: "center",
            fontSize: "16px",
            color: "black",
            textTransform: "uppercase",
            letterSpacing: "0.5px",
            lineHeight: "1.5",
            fontFamily: "Arial, sans-serif",
            mb: 1,
          }}
        >
          {headline || "Headline"}
        </Typography>

        {/* Description */}
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            maxWidth: "90%",
            textAlign: "center",
            fontSize: "14px",
            lineHeight: "1.5",
            fontFamily: "Arial, sans-serif",
          }}
        >
          {description || "Description of the product goes here"}
        </Typography>

        {/* Buttons */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
            mt: "auto",
          }}
        >
          {buttons.length > 0 ? (
            buttons.map((button, index) => (
              <Button
                key={index}
                variant={index === 0 ? "contained" : "outlined"}
                sx={{
                  bgcolor: index === 0 ? "black" : "transparent",
                  color: index === 0 ? "white" : "gray",
                  width: "90%",
                  borderRadius: "8px",
                  borderColor: "gray",
                }}
                href={button.url}
                target="_blank"
              >
                {button.name || `Button ${index + 1}`}
              </Button>
            ))
          ) : (
            <Box
              sx={{
                width: "90%",
                border: "2px dashed gray",
                color: "gray",
                borderRadius: "8px",
                p: 1,
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              Add Button
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ButtonTemplatePreview;
