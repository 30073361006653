import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import LandingPage from "./LandingPage/LandingPage";
import AppPage from "./Application/AppPage";
import SignUp from "./Auth/SignUp";
import Login from "./Auth/Login";
import ConnectorPage from "./ConnectorPage/ConnectorPage";
import APIHelper from "./LandingPage/utils/APIHelper";

function ProtectedRoute({ children, token }) {
  return token ? children : <Navigate to="/" replace />;
}

function App() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [hasAccessToken, setHasAccessToken] = useState(null); // null -> loading state

  const handleAuthSuccess = (token) => {
    setToken(token);
    localStorage.setItem("token", token);
    checkAccessToken(token);
  };

  const checkAccessToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);

      if (decodedToken.exp) {
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        if (decodedToken.exp > currentTime) {
          setHasAccessToken(Boolean(decodedToken.accessToken)); // Check if accessToken exists in payload
        } else {
          console.warn("Token has expired. Logging out...");
          logout(); // Expired token
        }
      } else {
        console.error("Token missing 'exp' claim. Logging out...");
        logout(); // Invalid token
      }
    } catch (error) {
      console.error("Error decoding token:", error);
      logout(); // Invalid token
    }
  };

  const logout = () => {
    localStorage.removeItem("token"); // Remove the token from localStorage
    setToken(null); // Clear token in state
    setHasAccessToken(false);
    window.location.href = "/"; // Redirect to root page
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
      checkAccessToken(storedToken);
      fetchLatestToken();
    } else {
      setHasAccessToken(false);
    }
  }, [token]);

  const fetchLatestToken = async () => {
    try {
      const response = await APIHelper.getToken(token);
      const updatedToken = response.token;
      setToken(updatedToken);
      localStorage.setItem("token", updatedToken);

      const decodedToken = jwtDecode(updatedToken);
      setHasAccessToken(Boolean(decodedToken.accessToken));

      checkAccessToken(updatedToken);
    } catch (error) {
      console.error("Error fetching latest token:", error);
    }
  };

  useEffect(() => {
    if (hasAccessToken && window.location.pathname === "/app") {
      window.location.href = "/dashboard"; // Ensure navigation to /dashboard
    }
  }, [hasAccessToken]);

  if (hasAccessToken === null) {
    // Show loading only during the initial check; not on invalid or expired tokens
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            token ? (
              hasAccessToken ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <Navigate to="/app" replace />
              )
            ) : (
              <LandingPage onAuthSuccess={handleAuthSuccess} />
            )
          }
        />
        <Route
          path="/app"
          element={
            <ProtectedRoute token={token}>
              {!token || (token && !hasAccessToken) ? (
                <ConnectorPage
                  token={token}
                  onAccessTokenUpdated={() => setHasAccessToken(true)} // Trigger state update when accessToken is added
                />
              ) : (
                <Navigate to="/dashboard" replace />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute token={token}>
              {hasAccessToken ? (
                <AppPage token={token} />
              ) : (
                <Navigate to="/app" replace />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/signup"
          element={<SignUp onAuthSuccess={handleAuthSuccess} />}
        />
        <Route
          path="/login"
          element={<Login onAuthSuccess={handleAuthSuccess} />}
        />
      </Routes>
    </div>
  );
}

export default App;
