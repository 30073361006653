import React from "react";
import MainContent from "./MainContent";
import PricingPage from "./PricingPage";
import TopBar from "./TopBar";

const LandingPage = ({ onAuthSuccess }) => {
  return (
    <div
      className="LandingPage"
      style={{
        fontFamily: "Arial, sans-serif",
        textAlign: "center",
        margin: 0,
        padding: 0,
      }}
    >
      {/* Top Bar */}
      <TopBar onAuthSuccess={onAuthSuccess} />

      {/* Main Content */}
      <MainContent />

      {/* Stats Section */}
      <StatsSection />

      {/* Pricing Section */}
      <PricingPage />

      {/* Footer */}
      <footer
        style={{
          padding: "10px",
          borderTop: "1px solid #ddd",
          marginTop: "20px",
          textAlign: "center",
        }}
      >
        {/* Footer Content */}
      </footer>
    </div>
  );
};

const StatsSection = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-around",
      padding: "20px",
      backgroundColor: "#f8f9fa",
    }}
  >
    <StatItem
      icon="⭐️⭐️⭐️⭐️⭐️"
      title="16,000+"
      description="Happy InsBuy Users"
    />
    <StatItem icon="📩" title="85,000,000+" description="Instagram DMs Sent" />
    <StatItem icon="🖱️" title="9 Million+" description="Monthly Link Clicks" />
    <StatItem
      icon="💬"
      title="4 Million+"
      description="Monthly Comments Sent"
    />
  </div>
);

const StatItem = ({ icon, title, description }) => (
  <div style={{ textAlign: "center" }}>
    <div style={{ fontSize: "2rem" }}>{icon}</div>
    <div style={{ fontSize: "1.5rem", fontWeight: "bold" }}>{title}</div>
    <div style={{ fontSize: "1rem", color: "#555" }}>{description}</div>
  </div>
);

export default LandingPage;
