import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@mui/material";
import AutoCommentConfig from "./AutoCommentConfig";

const PagePosts = ({ token, pageId }) => {
  const [posts, setPosts] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5);
  const [selectedPostId, setSelectedPostId] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (postId) => {
    setSelectedPostId(postId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedPostId(null);
  };

  useEffect(() => {
    if (pageId) {
      fetchPagePosts(pageId);
    }
  }, [pageId]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const fetchPagePosts = async (pageId) => {
    try {
      const postsResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/instagram/posts/${pageId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (postsResponse.status === 200) {
        setPosts(postsResponse.data); // Store all posts in the state
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  const handleViewMore = () => {
    setVisibleCount((prev) => prev + 5); // Show the next 5 posts
  };

  return (
    <Box>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        gap={2}
        mb={4}
      >
        {posts.slice(0, visibleCount).map((post) => (
          <Card
            key={post.id}
            sx={{ flex: "1 1 calc(20% - 16px)", maxWidth: "20%" }}
          >
            <CardMedia
              component="img"
              height="140"
              image={
                post.media_type === "VIDEO"
                  ? post.thumbnail_url
                  : post.media_url || "/placeholder-image.png"
              }
              alt={post.description || "Post image"}
            />
            <CardContent>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                }}
              >
                {post.caption}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                display="block"
                mt={1}
              >
                {formatTimestamp(post.timestamp)}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenModal(post.id)}
              >
                Configure
              </Button>

              <Button size="small" color="secondary">
                Skip
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
      {visibleCount < posts.length && (
        <Box display="flex" justifyContent="center" mt={2}>
          <Button variant="contained" color="primary" onClick={handleViewMore}>
            View More
          </Button>
        </Box>
      )}
      {isModalOpen && (
        <AutoCommentConfig
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          token={token}
          postId={selectedPostId}
        />
      )}
    </Box>
  );
};

export default PagePosts;
