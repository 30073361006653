import axios from "axios";

const APIHelper = {
  getToken: async (token) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/auth/get-token`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  },
};

export default APIHelper;
